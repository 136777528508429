import { Link } from 'gatsby';
import React from 'react';
import { OutboundLink } from 'gatsby-plugin-google-analytics';
import styled from 'styled-components';
import { links } from '../menu';

const NavbarContainer = styled.nav`
  display: flex;
  justify-content: space-around;
  max-width: 1000px;
  margin: 50px auto 0px auto;

  a {
    color: #edeeed;
    text-decoration: none;
  }

  @media (max-width: 766px) {
    display: none;
  }
`;

function Navbar() {
  return (
    <NavbarContainer role="navigation" aria-label="huvudmeny">
      {links.map((link, index) => (
        link.link.includes('http')
        ? <OutboundLink key={index} className="navbar-item" href={link.link} target="_blank" rel="noopener noreferrer">{link.label}</OutboundLink>
        : <Link key={index} className="navbar-item" to={link.link}>{link.label}</Link>
      ))}
    </NavbarContainer>
  );
}

export default Navbar;
