import React from 'react';
import PropTypes from 'prop-types';
import Img from 'gatsby-image';

const PreviewCompatibleImage = ({ alt, backgroundColor, image, style, editorialWidth, editorialHeight }) => {
  if (!!image && !!image.childImageSharp) {
      return !!image.childImageSharp.fluid
        ? <Img style={style} backgroundColor={backgroundColor} fluid={image.childImageSharp.fluid} alt={alt} />
        : <Img style={style} backgroundColor={backgroundColor} fixed={image.childImageSharp.fixed} alt={alt} />;
  }

  if (!!image && typeof image === 'string') return <img style={style} width={editorialWidth} height={editorialHeight} src={image} alt={alt} />;

  if (!!image && typeof image.path === 'string') return <img style={style} width={editorialWidth} height={editorialHeight} src={image.path} alt={alt} />;

  return null;
};

PreviewCompatibleImage.propTypes = {
  alt: PropTypes.string.isRequired,
  image: PropTypes.oneOfType([PropTypes.object, PropTypes.string]).isRequired,
  style: PropTypes.object,
  editorialWidth: PropTypes.string,
  editorialHeight: PropTypes.string,
};

export default PreviewCompatibleImage;
