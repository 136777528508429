import { Link } from 'gatsby';
import React from 'react';
import styled, { css } from 'styled-components';
import { OutboundLink } from 'gatsby-plugin-google-analytics';
import { colors, media } from '../css';
import { links } from '../menu';

const Container = styled.nav`
  display: none;

  ${media.phone`display: block;`}
`;

const MenuButton = styled.button`
  position: fixed;
  top: 0px;
  right: 0px;
  outline: none;
  z-index: 1050;
`;

const Navigation = styled.div`
  position: fixed;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  opacity: 0;
  background-color: ${colors.grey};
  transition: 200ms ease-in-out opacity;

  a {
    color: #edeeed;
    text-decoration: none;
    padding: 20px;
  }

  ${props => props.isActive && css`opacity: 1; z-index: 1000;`}
`;

class MobileNav extends React.Component{
  constructor(props) {
    super(props);
    this.state = {isActive: false};
  }

  setIsActive = isActive => this.setState({isActive});

  render() {
    let buttonClasses = 'hamburger hamburger--squeeze';
    if (this.state.isActive) {
      buttonClasses += ' is-active';
    }

    return (
      <Container>
        <MenuButton
          className={buttonClasses}
          onClick={() => this.setIsActive(!this.state.isActive)}
          type="button"
          aria-label="Meny"
          aria-controls="navigation"
          aria-expanded={this.state.isActive}
        >
          <span className="hamburger-box">
            <span className="hamburger-inner" />
          </span>
        </MenuButton>

        <Navigation isActive={this.state.isActive}>
          {links.map((link, index) => (
            link.link.includes('http')
            ? <OutboundLink key={index} className="navbar-item" href={link.link} target="_blank" rel="noopener noreferrer">{link.label}</OutboundLink>
            : <Link key={index} className="navbar-item" to={link.link} onClick={() => this.setIsActive(false)}>{link.label}</Link>
          ))}
        </Navigation>
      </Container>
    );
  }
}

export default MobileNav;
