import { css } from 'styled-components';
const sizes = {
  desktop: 992,
  tablet: 768,
  phone: 576,
};

export const colors = {
  gold: '#e2a204',
  blue: '#565e71',
  grey: '#454343',
  light: '#edeeed',
};

export const fontFamilies = {
  headers: 'Playfair Display',
  text: 'Raleway',
};

export const media = Object.keys(sizes).reduce((acc, label) => {
  acc[label] = (...args) => css`
    @media (max-width: ${sizes[label] / 16}em) {
      ${css(...args)}
    }
  `;

  return acc;
}, {});
