import React from 'react';
import styled from 'styled-components';
import { colors, media } from '../css';
import { OutboundLink } from 'gatsby-plugin-google-analytics';
import PreviewCompatibleImage from './PreviewCompatibleImage';
import logo from '../img/logo-horizontal.png';
import facebookIcon from '../img/facebook.svg';
import instagramIcon from '../img/instagram.svg';
import mailIcon from '../img/mail.svg';

const Container = styled.div`
  position: relative;
`;

const Logo = styled.img`
  width: 425px;
  max-width: 100%;

  ${media.phone`display: none;`}
`;
const Content = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 30px 20px;
  margin: 0 auto;
  max-width: 1000px;

  ${media.phone`flex-direction: column;`}
`;
const LinksContainer = styled.div`
  > a:not(:last-child) {
    margin-right: 10px;
  }
`;

function Footer({ background }) {
  return (
    <Container>
      <PreviewCompatibleImage
        image={background}
        alt="Grå bakgrund"
        backgroundColor={colors.grey}
        style={{
          position: 'absolute',
          left: 0,
          top: 0,
          width: '100%',
          height: '100%',
          zIndex: -1,
        }}
      />
      <Content>
        <Logo alt="Salong Västergården" src={logo} />
        <LinksContainer>
          <OutboundLink target="_blank" rel="noopener noreferrer" href="https://www.facebook.com/michaela.salongvastergarden/">
            <img alt="facebook" src={facebookIcon} />
          </OutboundLink>
          <OutboundLink target="_blank" rel="noopener noreferrer" href="https://www.instagram.com/salongvastergarden/">
            <img alt="instagram" src={instagramIcon} />
          </OutboundLink>
          <OutboundLink href="mailto:info@salongvastergarden.se">
            <img alt="mail" src={mailIcon} />
          </OutboundLink>
        </LinksContainer>
      </Content>
    </Container>
  );
}

export default Footer;
