import React from 'react';
import styled from 'styled-components';
import { media } from '../css';
import logo from '../img/logo.png';

const Link = styled.a`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 30px;
  
  ${media.phone`margin-bottom: 0px;`}
`;

const LogoHeader = styled.h1`
  margin: 0;
  padding: 0;
  font-size: -1px;
`;

const LogoImg = styled.img`
  height: 120px;

  ${media.tablet`height: 80px;`}
  ${media.phone`height: 60px;`}
`;

function Logo({ useHeader = false }) {
  return (
    <Link href="/" title="Länk till startsidan">
      {useHeader && (
        <LogoHeader>
          <LogoImg height="100px" src={logo} alt="Salong västergården | Frisör i Lidköping, nära Skövde och Mariestad" />
        </LogoHeader>
      )}
      {!useHeader && <LogoImg height="100px" src={logo} alt="Salong västergården | Frisör i Lidköping, nära Skövde och Mariestad" />}
    </Link>
  );
}

export default Logo;
